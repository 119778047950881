import React from "react"

export default function Select({
  name,
  label,
  options,
  placeholder,
  onChange,
  className,
}) {
  return (
    <div className="grid gap-2">
      <label htmlFor={name}>{label}</label>

      <select id={name} className={className} onChange={onChange}>
        <option value="" disabled>
          {placeholder}
        </option>
        {options}
      </select>
    </div>
  )
}
