import React, { useState, useEffect } from "react"
import { enGB } from "date-fns/locale"
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates"
import "react-nice-dates/build/style.css"
import { FaArrowRight } from "react-icons/fa"
import format from "date-fns/format"

export default function Dates({ setSearch, search, setDuration, from, to }) {
  const [startDate, setStartDate] = useState(from ? from : "")
  const [endDate, setEndDate] = useState(to ? to : "")

  useEffect(() => {
    if (startDate) {
      setSearch({ ...search, from: startDate })
    }

    if (endDate) {
      setSearch({ ...search, to: endDate })
    }

    if ((startDate, endDate)) {
      const duration = datediff(startDate, endDate)
      setDuration(duration)
    }
  }, [endDate, startDate])

  function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24))
  }
  return (
    <div>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        minimumDate={new Date()}
        minimumLength={1}
        format={"dd MMM yy"}
        locale={enGB}
      >
        {({ startDateInputProps, endDateInputProps, focus }) => (
          <div className="date-range flex space-x-6 items-center">
            <div className="grid w-full bg-white rounded-full py-5 relative">
              <label className="text-xs text-center">Check In</label>
              <input
                className={`w-full cursor-pointer caret-transparent hover:cursor-pointer py-2 focus:border-transparent focus:ring-transparent focus:ring-offset-transparent font-bold outline-none focus:outline-none bg-transparent text-center text-sm ${
                  "input" + (focus === START_DATE ? " -focused" : "")
                }`}
                {...startDateInputProps}
                placeholder="Choose a Date"
              />
              {startDate && (
                <span className="text-xs absolute bottom-3 w-full text-center text-gray-400">
                  {format(startDate, "EEEE")}
                </span>
              )}
            </div>
            <div>
              <FaArrowRight />
            </div>
            <div className="grid w-full py-5 bg-white rounded-full relative">
              <label className="text-xs text-center">Check Out</label>
              <input
                className={`w-full cursor-pointer caret-transparent hover:cursor-pointer py-2 focus:border-transparent focus:ring-transparent focus:ring-offset-transparent font-bold outline-none focus:outline-none bg-transparent text-center text-sm ${
                  "input" + (focus === END_DATE ? " -focused" : "")
                }`}
                {...endDateInputProps}
                placeholder="Choose a Date "
              />
              {endDate && (
                <span className="text-xs absolute bottom-3 w-full text-center text-gray-400">
                  {format(endDate, "EEEE")}
                </span>
              )}
            </div>
          </div>
        )}
      </DateRangePicker>
    </div>
  )
}
