import React, { useState } from "react"
import { Transition, Popover } from "@headlessui/react"
import Dates from "../Dates"
import PropertiesList from "../home/PropertiesList"
import Card from "../property/Card"
export default function Final({ data, properties, Price, Features }) {
  const [search, setSearch] = useState({
    from: data ? data.from : "",
    to: data ? data.to : "",
    looking: data ? data.looking : "",
    budget: data ? data.budget : "",
    guests: data ? data.guests : "",
  })
  const [duration, setDuration] = useState(0)
  console.log(search)
  const { from, to, looking, budget, guests } = search
  return (
    <section className="min-h-screen">
      <div className="bg-gray-100 py-4 sticky top-0 z-50 border-y">
        <div className="container mx-auto px-2 lg:px-8">
          <div className="flex justify-center flex-wrap gap-6">
            <Dates
              setSearch={setSearch}
              search={search}
              setDuration={setDuration}
              from={data ? from : new Date()}
              to={data ? to : new Date()}
            />
            <div className="grid gap-1 bg-white py-5 rounded-full">
              <label htmlFor="guests" className="text-xs text-center">
                Guests
              </label>
              <select
                id="guest"
                value={guests}
                onChange={e =>
                  setSearch({ ...search, guests: parseInt(e.target.value) })
                }
                className="bg-transparent appearance-none focus:ring-transparent focus:ring-offset-transparent focus:border-transparent outline-none focus:outline-none w-full px-10 text-center text-sm font-bold"
                placeholder="Guests"
              >
                <option value="" disabled>
                  Select
                </option>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, key) => {
                  return (
                    <option key={key} value={item}>
                      {item} Guest
                    </option>
                  )
                })}
              </select>
            </div>
            <div className="grid gap-1 bg-white py-5 rounded-full">
              <label htmlFor="preference" className="text-xs text-center">
                Preference
              </label>
              <Popover className="relative">
                <Popover.Button
                  type="button"
                  className="px-8 text-sm font-bold py-2"
                >
                  {looking.length} Preferences
                </Popover.Button>

                <Popover.Panel className="absolute w-64 mt-6  z-10">
                  <div className="grid gap-2 bg-white p-3 rounded"></div>
                </Popover.Panel>
              </Popover>
            </div>
            <div className="grid gap-1 bg-white py-5 rounded-full">
              <label htmlFor="budget" className="text-xs text-center">
                Budget
              </label>
              <select
                id="budget"
                value={budget}
                onChange={e =>
                  setSearch({ ...search, budget: parseInt(e.target.value) })
                }
                className="bg-transparent appearance-none focus:ring-transparent focus:ring-offset-transparent focus:border-transparent outline-none focus:outline-none w-full px-10 text-center text-sm font-bold"
                placeholder="Guests"
              >
                <option value="" disabled>
                  Select
                </option>
                {Price.map((item, key) => {
                  return (
                    <option key={key} value={item.id}>
                      {item.title}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="py-12 md:py-24 container mx-auto px-2 lg:px-8">
        {/* <PropertiesList hideLabel properties={properties} /> */}
        <div className="grid gap-12 md:grid-cols-2 xl:grid-cols-3">
          {Array.from(Array(3)).map(key => {
            return (
              <Card
                key={key}
                name="Villa Danny"
                area="dev tool"
                highlight="lorem ipsum"
                highPrice="3500"
                lowPrice="500"
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}
