import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { FaCheck } from "react-icons/fa"
import BeginningForm from "./BeginningForm"

export default function Beginning({ Price, Features, setCollect, setToggle }) {
  const way = [
    "Choose and Book your villa from the largest collection available",
    "Plan your trip using oyr Trip Planner, build your own personalised itinerary",
    "Book Experiences and Services to make your Ibiza trip of a lifetime",
  ]
  return (
    <>
      <section className="min-h-[80vh] relative">
        <div className="w-1/2 h-full bg-gray-50 absolute hidden md:block"></div>
        <div className="px-2 lg:px-8 container relative mx-auto grid md:grid-cols-2">
          <div className="py-6 bg-gray-50 px-6 md:px-2">
            <h1 className="py-6 w-full max-w-md">The Best Way to Book Ibiza</h1>
            <p className="max-w-md text-gray-400">
              X Over 500 of the bext Villas Ibiza has to offer
            </p>
            <p className="max-w-md text-gray-400">
              X Book the best experiences to make your stay incredible
            </p>
            <div className="py-6 md:py-12">
              <BeginningForm
                Features={Features}
                Price={Price}
                toggle={setToggle}
                collect={setCollect}
              />
            </div>
          </div>
          <div className="p-6">
            <div>
              {/* <StaticImage
                alt="plceholder"
                src="https://via.placeholder.com/1024x786"
              /> */}
            </div>
            <div>
              <ul className="grid gap-4 py-12">
                {[
                  "Trusted and curated collection of the best villas",
                  "Book Instantly with multiple ways to pay",
                  "Create your won customised holiday with experiences",
                ].map((item, key) => {
                  return (
                    <li key={key} className="flex space-x-3 ">
                      <span>
                        <FaCheck className="text-4xl" />
                      </span>
                      <span className="text-xl">{item}</span>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="py-12 md:py-24 ">
        <div className="container mx-auto px-2 lg:px-8">
          <h2 className="text-center">
            The new way to book your ibiza Experience
          </h2>
          <ul className="grid gap-4 md:gap-8 md:flex md:justify-between py-6 md:py-12">
            {way.map((item, key) => {
              return (
                <li key={key} className="flex space-x-3">
                  <span className="text-4xl font-serif">{key + 1}</span>
                  <span className="text-gray-500">{item}</span>
                </li>
              )
            })}
          </ul>
        </div>
      </section>
    </>
  )
}
