import { Transition } from "@headlessui/react"
import { format } from "date-fns"
import React, { useEffect, useState } from "react"
import Separator from "../../utils/Separator"
import Dates from "../Dates"
import Select from "../form/Select-2"

export default function BeginningForm({ collect, toggle, Features, Price }) {
  const [search, setSearch] = useState({
    to: undefined,
    from: undefined,
    guests: undefined,
    budget: undefined,
    looking: [],
  })
  const [duration, setDuration] = useState(0)
  const [summary, setSummary] = useState({
    max: 0,
    min: 0,
  })

  const { max } = summary
  const { from, to, looking, guests, budget } = search

  useEffect(() => {
    if (duration !== 0 && guests && budget) {
      const minSummary = duration * guests * Price[budget - 1].min
      const maxSummary = duration * guests * Price[budget - 1].max
      setSummary({ max: maxSummary, min: minSummary })
    }
  }, [duration, guests, budget, Price])

  const Submit = e => {
    e.preventDefault()
    collect({ ...search })
    toggle(true)
  }
  // console.log(features)
  return (
    <form onSubmit={Submit} className="grid gap-8 accent-black ">
      <div className="flex space-x-2 items-center">
        <div className={"w-full"}>
          <span className="pb-5">When are you coming?</span>
          <div className="grid items-center gap-4 py-2">
            <div>
              <Dates
                setDuration={setDuration}
                setSearch={setSearch}
                search={search}
              />
            </div>
          </div>
          {format(from ? from : new Date(), "eeee") === "Saturday" &&
            format(to ? to : new Date(), "eeee") === "Saturday" && (
              <span className="text-xs text-emerald-500 pt-2">
                You'll get better result from saturday to saturday
              </span>
            )}
        </div>
      </div>
      <Transition
        show={from || to ? true : false}
        enter="transition ease-in-out duration-1000"
        enterFrom="opacity-0 "
        enterTo="opacity-100"
        leave="transition ease-in-out duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0 "
        className={"w-full "}
      >
        <Select
          label="How many guests ?"
          name="guests"
          placeholder="Select Guest"
          className={`px-2 py-3 border bg-white font-thin  appearance-none text-xs md:text-lg outline-none focus:outline-none `}
          required
          onChange={e =>
            setSearch({ ...search, guests: parseInt(e.target.value) })
          }
          options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, key) => {
            return (
              <option key={key} value={item}>
                {item} Guest
              </option>
            )
          })}
        />
      </Transition>
      <Transition
        show={guests ? true : false}
        enter="transition ease-in-out duration-1000"
        enterFrom="opacity-0 "
        enterTo="opacity-100"
        leave="transition ease-in-out duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0 "
        className={"w-full"}
      >
        <div className="grid gap-2">
          <span htmlFor="looking">What are you looking for:</span>
          <div className="grid grid-cols-2 gap-2">
            {Features.map((item, key) => {
              return (
                <div key={key} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    onChange={e => {
                      if (e.target.checked === true) {
                        setSearch({
                          ...search,
                          looking: [
                            ...looking,
                            {
                              id: item.id,
                              title: item.title,
                              checked: true,
                            },
                          ],
                        })
                      } else {
                        setSearch({
                          ...search,
                          looking: looking.filter(
                            people => people.id !== item.id
                          ),
                        })
                      }
                    }}
                    value={looking}
                  />
                  <label className="text-sm font-bold">{item.title}</label>
                </div>
              )
            })}
          </div>
        </div>
      </Transition>
      <Transition
        show={looking.length !== 0 ? true : false}
        enter="transition ease-in-out duration-1000"
        enterFrom="opacity-0 "
        enterTo="opacity-100"
        leave="transition ease-in-out duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0 "
        className={"w-full"}
      >
        <Select
          label="What’s your budget per person per night?:"
          name="budget"
          placeholder="Select budget"
          required
          className={`px-2 py-3 border bg-white font-thin appearance-none text-xs md:text-lg outline-none focus:outline-none`}
          onChange={e =>
            setSearch({ ...search, budget: parseInt(e.target.value) })
          }
          options={Price.map((item, key) => {
            return (
              <option key={key} value={item.id}>
                {item.title}
              </option>
            )
          })}
        />
        {budget && (
          <span className="text-xs pt-4 text-emerald-500">
            Upto {Separator(max)} for your stay ({Price[budget - 1].title})
          </span>
        )}
      </Transition>
      <>
        <Transition
          show={from || to !== undefined ? true : false}
          enter="transition ease-in-out duration-1000"
          enterFrom="opacity-0 "
          enterTo="opacity-100"
          leave="transition ease-in-out duration-1000"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 "
          className="grid gap-2"
        >
          <>
            {looking.length !== 0 ? null : (
              <span className=" select-none text-center text-gray-300 italic">
                OR
              </span>
            )}
            <div className="flex justify-end w-full">
              <button
                type="submit"
                className={`px-5 py-4 text-xs md:text-sm uppercase tracking-widest bg-black w-full hover:bg-[#2a2a2a] hover:border  duration-150 ease-in-out transition  text-white `}
              >
                Show me the Villa
              </button>
            </div>
          </>
        </Transition>
      </>
    </form>
  )
}
