import React, { useState } from "react"
import Layout from "../components/new-design/Layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { Transition } from "@headlessui/react"
import Beginning from "../components/newWay/Beginning"
import Final from "../components/newWay/Final"
export default function NewWay({ data, location }) {
  const [collect, setCollect] = useState({})
  const [toggle, setToggle] = useState(false)
  return (
    <Layout>
      <Seo title="New Way" pathname={location.pathname} />
      <Transition
        show={toggle === true}
        enter="transition ease-in-out duration-1000"
        enterFrom="opacity-0 "
        enterTo="opacity-100"
        leave="transition ease-in-out duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0 "
      >
        <Final
          Price={Price}
          Features={Features}
          data={collect}
          properties={data.allSanityProperty.nodes}
        />
      </Transition>
      <Transition
        show={toggle === false}
        enter="transition ease-in-out duration-1000"
        enterFrom="opacity-0 "
        enterTo="opacity-100"
        leave="transition ease-in-out duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0 "
      >
        <Beginning
          Features={Features}
          Price={Price}
          setCollect={setCollect}
          setToggle={setToggle}
        />
      </Transition>
    </Layout>
  )
}

const Price = [
  { id: 1, title: "Up to €100", min: 50, max: 100 },
  { id: 2, title: "Up to €200", min: 150, max: 200 },
  { id: 3, title: "Up to €300", min: 250, max: 300 },
  { id: 4, title: "Up to €400", min: 350, max: 400 },
  { id: 5, title: "Up to €500", min: 450, max: 500 },
  { id: 6, title: "More than €500", min: 600, max: 700 },
]

const Features = [
  { id: 1, title: "Sea views", checked: false },
  { id: 2, title: "Modern Feel", checked: false },
  { id: 3, title: "Traditional feel", checked: false },
  { id: 4, title: "Great for Entertainment", checked: false },
  { id: 5, title: "Views of the Old Town", checked: false },
]

export const query = graphql`
  query {
    allSanityProperty(filter: { active: { eq: true } }) {
      nodes {
        _id
        slug {
          current
        }
        name
        bathrooms
        bedrooms
        area
        description {
          _key
          _type
          children {
            _key
            _type
            marks
            text
          }
          list
          style
        }
        propertyFeatures {
          name
        }
        highlight
        images {
          asset {
            gatsbyImageData
          }
        }
        name
        lowPrice
        highPrice
      }
    }
  }
`
